<template>
  <div class="content">
    <div class="loading" v-if="loading">拼命加载中...</div>
    <div v-else>
      <h3 class="title">{{ art.title }}</h3>
      <div class="info">
        <span>{{ art.cate.name }}</span>
        <!-- <span>{{ art.createTime }}</span> -->
      </div>
      <div class="html" v-html="html"></div>
    </div>
  </div>
</template>

<script>
import * as api from "@/apis/api";
export default {
  data() {
    return {
      loading: true,
      art: {},
      html: "",
    };
  },
  created() {
    this.loadDetail();
  },
  methods: {
    loadDetail() {
      this.loading = true;
      api.artDetail({ id: this.$route.query["id"] }).then((res) => {
        this.art = res;
        let html = this.art.content;
        // html = html.replace(/style\s*?=\s*?(['"])[\s\S]*?\1/gi, "");
        this.html = html;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.content {
  padding: 15px;
  background: #fff;
}
.title {
  font-weight: normal;
  padding: 0px;
  margin: 0;
}
.info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.loading {
  text-align: center;
  margin: 20% 0;
  color: #ccc;
}
</style>